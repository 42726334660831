// header.scss

@import '../../assets/css/foundation.scss';

.App {
  header {
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 105px;
    background: #FFF;
    border-bottom: 1px solid #E6E6E6;
    // backdrop-filter: blur(10px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @include transition(border-bottom, .5s);
    &.scroll_zero {
      background: transparent;
      border-bottom: transparent;
    }
    > .logo {
      z-index: 40;
      position: static;
      width: 130px;
      height: 32px;
      background: url(../../assets/images/logo_nextor.png) no-repeat 50% 50%;
      background-size: contain;
      text-indent: -9999px;
      overflow: hidden;
    }
    > .hamburger_button {
      display: none;
    }
    > .right_box {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      > .gnb {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        > li {
          margin-right: 32px;
          cursor: pointer;
          > p {
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 18px;
            color: $text-color-dark-gray;
            letter-spacing: normal;
          }
          
          &.default.all_leave {
            > p {
              color: $text-color-dark;
            }
          }

          &.mouse_over,
          &.on,
          &.on.mouse_over,
          &.on.all_leave {
            > p {
              color: $text-color-dark;
            }
          }

          &.default {
            > p {
              color: $text-color-dark;
            }
          }

          &.default.mouse_leave {
            > p {
              color: $text-color-dark-gray;
            }
          }
          
        }
      }
      > .language_box {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        >p {
          font-size: 16px;
          font-weight: 500;
          color: $text-color-dark;
          margin-right: 10px;
          letter-spacing: normal;
        }
        >.language_arrow {
          width: 16px;
          height: 24px;
          background: url(../../assets/images/language_arrow.png) no-repeat 50% 50%;
          background-size: contain;
          &.on {
            transform: rotate(180deg);
          }
        }
        >.language_box_inner {
          display: none;
          position: absolute;
          top: 30px;
          background: #FFF;
          padding: 12px 36px 12px 13px;
          border-radius: 10px;
          box-sizing: border-box;
          border: 1px solid #E6E6E6;
          >li {
            margin-bottom: 8px;
            color: #717D96;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            letter-spacing: normal;
            &:last-of-type {
              margin-bottom: 0;
            }
            &:hover,
            &.on {
              color: #2D3648;
            }
          }
        }
        &.on {
          >.language_box_inner {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .App {
    header {
      padding: 20px 32px;
      &.menu_open {
        &.scroll_zero {
          background: #FFF;
          border-bottom: 1px solid #E6E6E6;
        }
      }
      > .hamburger_button {
        z-index: 40;
        position: static;
        display: block;
        width: 30px;
        height: 30px;
        background: url(../../assets/images/header_menu.png) no-repeat 50% 50%;
        background-size: contain;
        cursor: pointer;
        &.on {
          background: url(../../assets/images/header_menu_x.png) no-repeat 50% 50%;
          background-size: contain;
        }
      }
      > .right_box {
        z-index: 30;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 72px;
        > .gnb {
          display: none;
          position: fixed;
          top: 73px;
          left: 0;
          width: 100%;
          height: calc(100vh - 72px);
          flex-flow: row nowrap;
          align-items: center;
          background: #FFF;
          flex-flow: column nowrap;
          &.on {
            display: block;
          }
          > li {
            width: 100%;
            margin-right: 0;
            > p {
              width: 100%;
              height: 100%;
              font-weight: 500;
              font-size: 18px;
              color: #717D96;
              text-align: left;
              padding: 22px 32px;
            }
            &:hover,
            &.on {
              > p {
                color: #2D3648;
              }
            }
          }
        }
        > .language_box {
          position: absolute;
          top: 27px;
          right: 110px;
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          >p {
            display: none;
            font-size: 16px;
            font-weight: 500;
            color: #757A88;
            margin-right: 10px;
          }
          >.language_arrow {
            display: none;
            width: 16px;
            height: 24px;
            background: url(../../assets/images/language_arrow.png) no-repeat 50% 50%;
            background-size: contain;
            &.on {
              transform: rotate(180deg);
            }
          }
          >.language_box_inner {
            display: flex !important;
            flex-flow: row nowrap;
            position: absolute;
            top: 0;
            background: none;
            padding: 0;
            border-radius: 10px;
            box-sizing: border-box;
            border: none;
            >li {
              margin-bottom: 8px;
              color: #717D96;
              font-size: 16px;
              font-weight: 500;
              margin-right: 20px;
              cursor: pointer;
              &:last-of-type {
                margin-bottom: 0;
              }
              &:hover,
              &.on {
                color: #2D3648;
                text-decoration-line: underline;
              }
            }
          }
          &.on {
            >.language_box_inner {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 704px) {
}