// topButton.scss

@import '../../assets/css/foundation.scss';

.top_button {
  z-index: 50;
  opacity: 0;
  display: none;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  @include image-style(48px, 48px, url(../../assets/images/top_button.png) no-repeat 50% 50%, contain);
  @include transition(bottom, 1s);
  &.on {
    opacity: 1;
    display: block;
  }
  &.bottom {
    bottom: 180px;
  }
}

@media (max-width: 1130px) {
  .top_button {
    &.bottom {
      bottom: 200px;
    }
  }
}

@media (max-width: 704px) {
  .top_button {
    bottom: 20px;
    right: 20px;
    &.bottom {
      bottom: 240px;
      &.en {
        bottom: 270px;
      }
    }
  }
}