// news.scss

@import '../../assets/css/foundation.scss';

.container {
  &.news {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 135px);
    @include flex(flex, $display-flex-column-no, center, flex-start);
    padding-bottom: 120px;
    > .news_title {
      margin-top: 192px;
      margin-bottom: 80px;
      width: $web-width;
      @include text-style($text-color-dark, 38px, 700, 53px, left);
      letter-spacing: normal;
    }
    > .content_frame {
      width: $web-width;
      @include flex(flex, $display-flex-column-no, center, flex-start);
      > .news_menu {
        width: 100%;
        @include flex(flex, $display-flex-row-no, center, space-between);
        > p {
          position: relative;
          flex: 1;
          @include flex(flex, $display-flex-row-no, center, center);
          @include text-style($text-color-dark-gray, 14px, 600, 14px, center);
          padding: 16px 0;
          cursor: pointer;
          letter-spacing: normal;
          &.on,
          &:hover {
            color: $text-color-dark;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 2px;
              background: $text-color-dark;
            }
          }
        }
      }
      > .no_data {
        width: 100%;
        height: 200px;
        border-bottom: 1px solid #E6E6E6;
        @include flex(flex, $display-flex-column-no, center, center);
        > p {
          @include text-style($text-color-dark-gray, 16px, 400, 26px, center);
          letter-spacing: normal;
        }
      }
      > .news_items_box {
        width: 100%;
        margin-bottom: 100px;
        > .news_items {
          width: 100%;
          @include flex(flex, $display-flex-row-no, center, space-between);
          border-bottom: 1px solid #E6E6E6;
          cursor: pointer;
          > .left_box {
            flex: 4;
            padding: 40px 0;
            > .icon {
              display: inline-block;
              @include text-style($text-color-white, 12px, 700, 19px, center);
              border-radius: 20px;
              padding: 2px 12px;
              margin-bottom: 16px;
              letter-spacing: normal;
              &.News {
                background: $text-color-news;
              }
              &.Notice {
                background: $text-color-notice;
              }
            }
            > .news_item_title {
              @include text-style($text-color-dark, 22px, 700, 30px, left);
              margin-bottom: 10px;
            }
            > .news_description {
              > div, p {
                display: none;
              }
              > p:first-of-type {
                @include text-style($text-color-dark-gray, 16px, 400, 26px, left);
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0;
                white-space: pre-line;
                word-break: break-all;
                > br {
                  display: none !important;
                }
              }
            }
          }
          > .news_item_date {
            flex: 1;
            @include text-style($text-color-dark-gray, 16px, 400, 16px, center);
          }
          > .news_items_button {
            @include image-style(52px, 52px, url(../../assets/images/news/news_item_button.png) no-repeat 50% 50%, contain);
            transform: rotate(180deg);
          }
        }
      }
      > .news_pagination {
        width: 100%;
        height: 64px;
        @include flex(flex, $display-flex-row-no, center, center);
        > li {
          width: 62px;
          height: 21px;
          @include flex(flex, $display-flex-row-no, center, center);
          text-align: center;
          &.previous,
          &.next {
            > a {
              @include image-style(24px, 24px, url(../../assets/images/news/news_previous.png) no-repeat 50% 50%, contain);
              &:hover,
              &:active {
                @include background-image(url(../../assets/images/news/news_next.png) no-repeat 50% 50%, contain);
                transform: rotate(180deg);
              }
            }
          }
          &.next {
            > a {
              transform: rotate(180deg);
              &:hover,
              &:active {
                transform: rotate(0);
              }
            }
          }
          &.selected {
            > a {
              color: $text-color-news;
              text-decoration-line: underline;
            }
          }
          &:hover {
            text-decoration-line: underline;
          }
          > a {
            @include text-style($text-color-dark-gray, 18px, 400, 20px, center);
          }
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .container {
    &.news {
      min-height: calc(100vh - 161px);
      > .news_title {
        margin-top: 152px;
        margin-bottom: 60px;
        width: $tablet-width;
      }
      > .content_frame {
        width: $tablet-width;
        > .news_items_box {
          margin-bottom: 75px;
          > .news_items {
            @include flex(flex, $display-flex-column-no, flex-start, center);
            padding: 40px 0;
            > .left_box {
              flex: inherit;
              padding: 0;
              > .news_description {
                margin-bottom: 20px;
                > p {
                  &:first-of-type {
                    width: $tablet-width;
                    -webkit-line-clamp: 2;
                  }
                }
              }
            }
            > .news_item_date {
              width: 100%;
              @include text-style($text-color-dark-gray, 16px, 400, 16px, left);
            }
            > .news_items_button {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 704px) {
  .container {
    &.news {
      min-height: calc(100vh - 225px);
      padding-bottom: 95px;
      > .news_title {
        margin-top: 132px;
        margin-bottom: 40px;
        width: $mobile-width;
      }
      > .content_frame {
        width: $mobile-width;
        > .news_items_box {
          margin-bottom: 50px;
          > .news_items {
            @include flex(flex, $display-flex-column-no, flex-start, center);
            padding: 40px 0;
            > .left_box {
              flex: inherit;
              padding: 0;
              > .news_description {
                margin-bottom: 20px;
                > p {
                  &:first-of-type {
                    width: $mobile-width;
                    -webkit-line-clamp: 3;
                  }
                }
              }
            }
            > .news_item_date {
              width: 100%;
              @include text-style($text-color-dark-gray, 16px, 400, 16px, left);
            }
            > .news_items_button {
              display: none;
            }
          }
        }
      }
    }
  }
  .ko {
    .container {
      &.news {
        min-height: calc(100vh - 201px);
      }
    }
  }
}