// notFound.scss

@import '../../assets/css/foundation.scss';

.container_not_found {
  width: 100%;
  height: calc(100vh - 135px);
  padding-top: 72px;
  box-sizing: border-box;
  background: #F7F9FC;
  @include flex(flex, $display-flex-column-no, center, center);
  > .title {
    width: 100%;
    @include text-style($text-color-dark, 48px, 900, 67px, center);
    margin-bottom: 20px;
  }
  > .description {
    width: 100%;
    @include text-style($text-color-dark-gray, 16px, 400, 24px, center);
    margin-bottom: 40px;
  }
  > .button_box {
    @include flex(flex, $display-flex-row-no, center, center);
    > .button {
      margin-right: 10px;
      &:hover {
        background: none;
        > p {
          color: $text-color-dark;
        }
      }
      &:last-of-type {
        margin-right: 0;
        background: $text-color-dark;
        > p {
          color: $text-color-white;
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .container_not_found {
    height: calc(100vh - 161px);
    > .title {
      @include text-style($text-color-dark, 40px, 700, 48px, center);
    }
    > .description {
      @include text-style($text-color-dark-gray, 15px, 400, 22px, center);
    }
  }
}

@media (max-width: 704px) {
  .container_not_found {
    height: calc(100vh - 201px);
    padding: 72px 32px 0 32px;
    > .title {
      @include text-style($text-color-dark, 36px, 700, 43px, center);
    }
  }
}

@media (max-width: 540px) {
  .container_not_found {
    height: calc(100vh - 225px);
  }
  .ko {
    .container_not_found {
      height: calc(100vh - 201px);
    }
  }
}