// window-size
$web-width: 1128px;
$web-padding-top: 200px;

$tablet-width: 704px;
$tablet-padding-top: 120px;

$mobile-width: 310px;

// text-color
$text-color-dark: #2D3648;
$text-color-white: #FFF;
$text-color-light-gray: #CBD2E0;
$text-color-dark-gray: #717D96;
$text-color-news: #34AFF7;
$text-color-notice: #7860EA;

// font-weight
$font-weight-7: 700; 
$font-weight-6: 600; 
$font-weight-4: 400;

// font-size-web
$font-size-110: 110px;
$font-size-82: 82px;
$font-size-46: 46px;
$font-size-38: 38px;
$font-size-22: 22px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;

// font-size-tablet
$font-size-40: 40px;
$font-size-28: 28px;
$font-size-22: 22px;
$font-size-16: 16px;

// font-size-mobile
$font-size-48: 48px;

// line-height-web
$line-height-135: 135px;
$line-height-35: 35px;

// line-height-tablet
$line-height-100: 100px;
$line-height-26: 26px;

// flex-flow
$display-flex-column-no: column nowrap;
$display-flex-row-no: row nowrap;
$display-flex-column-wrap: column wrap;
$display-flex-row-wrap: row wrap;

@keyframes marquee {
  0% { left: 100%; }
  100% { left: -100%; }
}

@mixin animation-marquee($duration, $delay) {
  animation: marquee $duration linear $delay infinite;
}

@mixin flex ($display, $flex-flow, $align-items, $justify-content) {
  display: $display;
  flex-flow: $flex-flow;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin text-style ($color, $size, $weight, $line-height, $align) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  text-align: $align;
}

@mixin image-style ($width, $height, $background, $background-size) {
  width: $width;
  height: $height;
  background: $background;
  background-size: $background-size;
}
@mixin background-image ($background, $background-size) {
  background: $background;
  background-size: $background-size;
}

@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}