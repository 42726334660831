// newsDetail.scss

@import '../../assets/css/foundation.scss';


.news_detail {
  z-index: 20;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 135px);
  @include flex(flex, $display-flex-column-no, center, center);
  padding-top: 72px;
  padding-bottom: 120px;

  >.news_header {
    @include flex(flex, $display-flex-column-no, flex-start, center);
    width: $web-width;
    padding-top: 120px;
    border-bottom: 1px solid #E6E6E6;

    >.icon {
      @include text-style($text-color-white, 12px, 700, 18px, center);
      border-radius: 20px;
      padding: 2px 12px;
      margin-bottom: 16px;
      letter-spacing: normal;

      >&.News {
        background: $text-color-news;
      }

      >&.Notice {
        background: $text-color-notice;
      }
    }

    >.title {
      margin-bottom: 16px;
      @include text-style($text-color-dark, 38px, 700, 52px, left);
      letter-spacing: 0.01em;
    }

    >.date {
      @include text-style($text-color-dark-gray, 16px, 400, 16px, left);
      margin-bottom: 24px;
    }

    >.board_detail_attachment {
      width: 100%;
      margin-bottom: 24px;

      >.title {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #717D96;
        margin-bottom: 8px;
      }

      >.box {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        >.button_attachment {
          position: relative;
          padding: 9px 9px 9px 30px;
          border: 1px solid #EDF0F7;
          border-radius: 4px;
          cursor: pointer;

          >p {
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #34AFF7;
            // text-indent: 21px;
          }

          &::after {
            content: "";
            position: absolute;
            top: calc(50% - 8px);
            left: 9px;
            width: 16px;
            height: 16px;
            background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0.666992C3.46957 0.666992 2.96086 0.877706 2.58579 1.25278C2.21071 1.62785 2 2.13656 2 2.66699V13.3337C2 13.8641 2.21071 14.3728 2.58579 14.7479C2.96086 15.1229 3.46957 15.3337 4 15.3337H12C12.5304 15.3337 13.0391 15.1229 13.4142 14.7479C13.7893 14.3728 14 13.8641 14 13.3337V6.00033C14 5.82351 13.9298 5.65394 13.8047 5.52892L9.13807 0.862274C9.07427 0.798475 8.99887 0.748923 8.91683 0.71571C8.8381 0.683836 8.75325 0.666992 8.66667 0.666992H4ZM8 2.00033H4C3.82319 2.00033 3.65362 2.07056 3.5286 2.19559C3.40357 2.32061 3.33333 2.49018 3.33333 2.66699V13.3337C3.33333 13.5105 3.40357 13.68 3.5286 13.8051C3.65362 13.9301 3.82319 14.0003 4 14.0003H12C12.1768 14.0003 12.3464 13.9301 12.4714 13.8051C12.5964 13.68 12.6667 13.5105 12.6667 13.3337V6.66701H8.66667C8.29848 6.66701 8 6.36853 8 6.00034V2.00033ZM11.7239 5.33368L9.33333 2.94315V5.33368H11.7239Z' fill='%23A0ABC0'/%3E%3C/svg%3E%0A") no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }

  >.news_body {
    @include flex(flex, $display-flex-column-no, center, center);
    width: $web-width;

    >.news_detail_content {
      width: 100%;
      border-bottom: 1px solid #E6E6E6;
      padding: 40px 0;
      margin-bottom: 80px;

      >p {
        width: 100%;
        // @include text-style($text-color-dark-gray, 16px, 400, 26px, left);
        word-wrap: break-word !important;
      }

      >.se-image-container {
        @include flex(flex, $display-flex-row-no, center, center);
        >figure {
          >img {
            max-width: $web-width;
            height: auto !important;
          }
        }
      }

      .se-video-container {
        figure {
          padding-bottom: 80px !important;
        }
      }

      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        font-size: inherit;
        font: inherit;
        vertical-align: inherit;
        font-family: inherit;
        color: inherit;
        font-size: inherit;
        -webkit-user-select: inherit;
        user-select: inherit;
        -webkit-font-smoothing: inherit;
        letter-spacing: inherit;
      }

      ol,
      ul,
      li {
        list-style: unset;
      }

      ol,
      ul {
        list-style-position: outside;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
      }

      ol {
        list-style-type: decimal;
      }

      ul {
        list-style-type: disc;
      }

      li {
        display: list-item;
        text-align: -webkit-match-parent;
        margin-bottom: 5px;

        &::marker {
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
        }
      }

      pre {
        display: block;
        padding: 8px;
        margin: 0 0 10px;
        font-family: monospace;
        color: #666;
        line-height: 1.45;
        background-color: #f9f9f9;
        border: 1px solid #e1e1e1;
        border-radius: 2px;
        white-space: pre-wrap !important;
        word-wrap: break-word;
        overflow: visible;
      }

      blockquote {
        display: block;
        font-family: inherit;
        font-size: inherit;
        color: #999;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding: 0 5px 0 20px;
        border: solid #b1b1b1;
        border-width: 0 0 0 5px;
      }

      h1,
      h2 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
      }

      h1 {
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
      }

      h2 {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
      }

      h3,
      h4 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
      }

      h3 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
      }

      h4 {
        font-size: 1em;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
      }

      h5,
      h6 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700;
      }

      h5 {
        font-size: .83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
      }

      h6 {
        font-size: .67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
      }

      .__se__p-spaced {
        letter-spacing: 1px;
      }

      .__se__p-bordered {
        border-top: 1px solid #b1b1b1;
        border-bottom: 1px solid #b1b1b1;
        padding: 4px 0;
      }

      .__se__p-neon {
        font-weight: 200;
        font-style: italic;
        background: #000;
        color: #fff;
        padding: 6px 4px;
        border: 2px solid #fff;
        border-radius: 6px;
        text-transform: uppercase;
        animation: neonFlicker 1.5s infinite alternate;
      }

      p {
        display: block;
        margin: 0 0 10px;
      }

      strong {
        font-weight: 600;
      }

      em {
        font-style: italic;
      }

      span,
      strong,
      del,
      u {
        // display: inline-block;
      }

      hr {
        &.__se__dashed {
          border-style: dashed none none;
        }

        &.__se__solid {
          border-style: solid none none;
        }

        &.__se__dotted {
          border-style: dotted none none;
        }
      }

      table {
        display: table;
        table-layout: auto !important;
        border: 1px solid #ccc;
        width: 100%;
        max-width: 100%;
        margin: 0 0 10px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
          border-bottom: 2px solid #333;

        }

        tr {
          border: 1px solid #efefef;
        }

        th {
          background-color: #f3f3f3;
        }

        td,
        th {
          border: 1px solid #e1e1e1;
          padding: 0.4em;
          background-clip: padding-box;
        }
      }
    }

    >.news_footer {
      width: 100%;
      @include flex(flex, $display-flex-row-no, center, space-between);

      >.news_button {
        @include flex(flex, $display-flex-row-no, center, center);
        @include text-style($text-color-dark-gray, 16px, 400, 16px, center);
        cursor: pointer;
        height: 52px;
        box-sizing: border-box;

        &.before,
        &.next {
          position: relative;
          justify-content: flex-end;
          // text-indent: 62px;
          width: 110px;

          &::after {
            position: absolute;
            top: calc(50% - 26px);
            left: 0;
            content: "";
            @include image-style(52px, 52px, url(../../assets/images/news/news_detail_previous.png) no-repeat 50% 50%, contain);
          }

          &:hover {
            color: $text-color-dark;

            &::after {
              @include image-style(52px, 52px, url(../../assets/images/news/news_item_button.png) no-repeat 50% 50%, contain);
            }
          }
        }

        &.news_main {
          position: relative;
          border: 1px solid $text-color-dark;
          padding: 18px 50px 18px 30px;
          border-radius: 26px;
          color: $text-color-dark;

          &::after {
            position: absolute;
            top: calc(50% - 7px);
            right: 30px;
            content: "";
            @include image-style(14px, 14px, url(../../assets/images/news/news_item_list_icon.png) no-repeat 50% 50%, contain);
          }

          &:hover {
            background: $text-color-dark;
            color: $text-color-white;

            &::after {
              @include image-style(14px, 14px, url(../../assets/images/news/news_item_list_icon_w.png) no-repeat 50% 50%, contain);
            }
          }
        }

        &.next {
          // text-indent: 0;
          padding-right: 62px;
          justify-content: flex-start;

          &::after {
            left: inherit;
            right: 0;
            transform: rotate(180deg);
          }
        }

        &.hide {
          display: none;
        }
      }
    }
  }
}


@media (max-width : 1130px) {
  .news_detail {
    min-height: calc(100vh - 161px);

    >.news_header {
      width: $tablet-width;
      padding-top: 80px;
    }

    >.news_body {
      width: $tablet-width;

      >.news_detail_content {
        >.se-image-container {
          >figure {
            >img {
              max-width: $tablet-width;
            }
          }
        }
      }
    }
  }
}

@media (max-width : 704px) {
  .news_detail {
    min-height: calc(100vh - 225px);

    >.news_header {
      width: $mobile-width;
      padding-top: 80px;

      >.title {
        @include text-style($text-color-dark, 28px, 700, 39px, left);
      }
    }

    >.news_body {
      width: $mobile-width;

      >.news_detail_content {
        margin-bottom: 40px;

        >.se-image-container {
          >figure {
            >img {
              max-width: $mobile-width;
            }
          }
        }
      }

      >.news_footer {
        >.news_button {
          @include text-style($text-color-dark-gray, 14px, 400, 14px, center);
          height: 41px;

          &.before,
          &.next {
            width: 41px;
            height: 41px;
            text-indent: -9999px;
            overflow: hidden;
            padding: 0;

            &::after {
              top: calc(50% - 20.5px);
              @include image-style(41px, 41px, url(../../assets/images/news/news_detail_previous.png) no-repeat 50% 50%, contain);
            }

            &:hover {
              &::after {
                @include image-style(41px, 41px, url(../../assets/images/news/news_item_button.png) no-repeat 50% 50%, contain);
              }
            }
          }

          &.news_main {
            padding: 14px 70px 14px 50px;

            &::after {
              right: 50px;
            }
          }
        }

        >.empty {
          width: 41px !important;
        }
      }
    }
  }

  .App {
    &.ko {
      .news_detail {
        min-height: calc(100vh - 201px);
      }
    }
  }
}