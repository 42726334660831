// normalButton.scss

.button {
  width: auto !important;
  margin-bottom: 0 !important;
  padding: 18px 30px !important;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #2D3648;
  border-radius: 26px;
  > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #2D3648;
    letter-spacing: normal !important;
  }
  > .icon {
    position: inherit;
    width: 12px !important;
    height: 12px;
    padding-bottom: inherit !important;
    background: url(../../../assets/images/button_out.png) no-repeat 50% 50%;
    background-size: contain;
  }
  &:hover {
    background: #2D3648;
    > p {
      color: #FFFFFF;
    }
    > .icon {
      background: url(../../../assets/images/button_out_white.png) no-repeat 50% 50%;
      background-size: contain;
    }
  }
  &.out {
    > p {
      margin-right: 5px;
    }
  }
}