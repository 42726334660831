// footer.scss

.App {
  > footer {
    z-index: 40;
    position: inherit;
    width: 100%;
    padding: 40px 100px 40px 100px;
    background: #2D3648;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    box-sizing: border-box;
    > .logo {
      width: 76px;
      height: 20px;
      background: url(../../assets/images/logo_nextor_g.png) no-repeat 50% 50%;
      background-size: contain;
      text-indent: -9999px;
      overflow: hidden;
      margin-right: 145px;
    }
    > .right_box {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      > .info {
        width: 100%;
        margin-bottom: 12px;
        > span {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #717D96;
          margin-right: 20px;
          &:first-of-type {
            font-weight: 700;
          }
        }
      }
      > .copyright {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #717D96;
        letter-spacing: normal;
      }
    }
  }
}

@media (max-width: 1130px) {
  .App {
    > footer {
      padding: 20px 32px 40px 32px;
      > .logo {
        margin-right: 60px;
      }
      > .right_box {
        > .info {
          > span {
            &:nth-of-type(3) {
              display: block;
            }
          }
        }
        > .copyright {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}

@media (max-width: 704px) {
  .App {
    > footer {
      flex-flow: column nowrap;
      > .logo {
        margin-bottom: 20px;
      }
    }
  }
}